<template>
	<section class="min-w-0 flex-1 flex flex-col border border-red-500" style="width: 220px">
		<header class="mb-4 px-3 py-2 border-b border-b-gray-400">
			<h2>{{ title }}</h2>
		</header>
		<div class="content">

			<template v-for="item in data">

				<KanbanCard v-bind="item" />

			</template>

		</div>
	</section>
</template>
<script>
import KanbanCard from "@/components/Kanban/KanbanCard.vue";

export default {
	name: "KanbanStage",
	components: {KanbanCard},
	props: {
		title: String,
		data: Array,
		width: 220
	}
}
</script>