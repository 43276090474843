<template>
	<div class="drop-base fixed pointer-events-none inset-0 w-screen h-screen">
		<transition
			enter-active-class="transition ease-out duration-100"
			enter-class="transform opacity-0 scale-95"
			enter-to-class="transform opacity-100 scale-100"
			leave-active-class="transition ease-in duration-75"
			leave-class="transform opacity-100 scale-100"
			leave-to-class="transform opacity-0 scale-95">
			<div v-if="state.show" ref="drop"
				 class="dropdown-content drop-container absolute origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[91] pointer-events-auto translate-x-[calc(-15%)] translate-y-4"
				 :class="dropClass" :style="floatingStyles">
				<div class="w-56 py-1">
					<slot>
						<template v-for="(item, index) in items">
							<template v-if="item.icon">
								<a :key="`item-${index}`" @click="handleClick(item)"
								   class="group flex items-center px-4 py-2 text-sm cursor-pointer leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
								   role="menuitem"
								   :class="item.clss">
									<i class="mr-3" :class="[{'text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500' : !item.iconColor },item.iconColor,item.icon]"></i>
									{{ item.text }}
								</a>
							</template>
							<template v-else-if="item === '--' || item.divider">
								<div :key="`item-${index}`" class="border-t" :class="[{'border-gray-100' : !item.color}, item.color]"></div>
							</template>
							<a v-else :key="`item-${index}`" @click="handleClick(item)"
							   class="block px-4 py-2 cursor-pointer text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">
								{{ item }}
							</a>
						</template>
					</slot>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import {computePosition, flip, offset, autoPlacement} from "@floating-ui/vue"
import {useMouse} from "@vueuse/core";

const {x, y} = useMouse()

export default {
	name: "FloatContextMenu",
	props: {
		items: Array,
		containerWidth: {
			type: String,
			default: 'auto'
		},
		text: {
			type: String,
			default: 'Selecione'
		},
		dropClass: String,
		placement: {
			type: String,
			default: 'bottom-start'
		},
		target: HTMLElement,
		open: Boolean,
		offsetX: {
			type: Number,
			default: 0
		},
		offsetY: {
			type: Number,
			default: 0
		}
	},
	model: {
		prop: 'open',
		event: 'update:open'
	},
	data() {
		return {
			floatingStyles: null,
			state: {
				show: this.open
			}
		}
	},
	provide() {
		return {
			dropdownState: this.state
		}
	},
	methods: {
		handleToggle() {
			this.state.show = !this.state.show
		},
		handleClick(item) {
			this.state.show = false
			this.$emit('input', item)
		},
		handleClickAway(e) {
			const dropBase = e.target.closest('.drop-base')
			const dropContainer = e.target.closest('.drop-container')
			if (dropBase && dropBase === this.target) return
			if (dropContainer && dropContainer === this.$refs.drop) return

			this.state.show = false
		},
		handlePressEsc(e) {
			if (e.keyCode === 27) this.state.show = false
		}
	},
	watch: {
		open(value) {
			this.state.show = value
		},
		'state.show'(value) {

			if (value) {
				document.addEventListener('keydown', this.handlePressEsc)
				document.addEventListener('mousedown', this.handleClickAway)
				document.body.classList.add('overflow-hidden')

				this.$nextTick(() => {
					Object.assign(this.$refs.drop.style, {
						left: `${window.event.clientX + this.offsetX}px`,
						top: `${window.event.clientY + this.offsetY}px`,
					});
				})
			} else {
				document.body.classList.remove('overflow-hidden')
				document.removeEventListener('keydown', this.handlePressEsc)
				document.removeEventListener('mousedown', this.handleClickAway)
			}

			this.$emit('update:open', value)
		}
	}
}
</script>
<style scoped>
.dropdown-content {
	/* Float on top of the UI */
	position: absolute;

	/* Avoid layout interference */
	width: max-content;
	top: 0;
	left: 0;
}
</style>