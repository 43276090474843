<template>
	<div class="px-4 py-5 bg-white shadow sm:rounded overflow-hidden sm:p-6">
		<dt class="text-sm font-medium text-gray-500 truncate">
			{{ title }}
		</dt>
		<dd class="mt-1 flex items-center">
			<slot/>
			<template v-if="withChart">
				<div class="ml-auto w-36 h-12">
					<canvas ref="chartTarget"></canvas>
				</div>
			</template>
		</dd>
	</div>
</template>

<script>
import Chart from "chart.js/auto"

export default {
	name: "KPICard",
	props: {
		title: String,
		chart: {
			type: Object,
		}
	},
	data() {
		return {
			chartElement: null
		}
	},
	computed: {
		withChart() {
			return this.chart != null
		}
	},
	methods: {
		renderChart() {
			if (!this.withChart) return

			let borderColor
			const labels = [...Array(this.chart.data.length).keys()]

			// for(let i = 0; i < this.chart.data.length; i++) labels.push(i)

			if (Array.isArray(this.chart.borderColor)) {
				borderColor = this.$refs.chartTarget.getContext('2d').createLinearGradient(0, 0, 144, 0);
				const itemWeight = 1 / this.chart.borderColor.length
				for (let i = 0; i < this.chart.borderColor.length; i++) {
					borderColor.addColorStop(itemWeight * i, this.chart.borderColor[i]);
				}
			} else {
				borderColor = this.chart.borderColor
			}

			this.chartElement = new Chart(this.$refs.chartTarget, {
				type: 'line',
				data: {
					labels,
					datasets: [
						{
							data: this.chart.data,
							tension: 0.4,
							pointStyle: false,
							borderColor: borderColor,
							borderWidth: 2
						},
					]
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
					hover: {mode: null},
					scales: {
						x: {display: false},
						y: {display: false}
					},
					plugins: {
						legend: {display: false},
						tooltip: {enabled: false}
					}
				},
			});
		},
		updateChart(v){
			this.chartElement.data.labels = [...Array(v.data.length).keys()];
			this.chartElement.data.datasets[0].data = v.data
			this.chartElement.update()
		}
	},
	watch: {
		chart(v) {
			this.updateChart(v)
		}
	},
	mounted() {
		this.renderChart()
	}
}
</script>