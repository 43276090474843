import SupportTickets from "@/API/backoffice/SupportTickets.js"

import LoadingMixin from "@/mixins/loading.js"
import SystemMixin from "@/mixins/system.js"
import HandleRequestErrorsMixin from "@/mixins/handle_request_errors.js"

/**
 * TODO: Remove vuex-pathify dependency
 */
import {sync} from "vuex-pathify"

export default {
    mixins: [
        LoadingMixin,
        SystemMixin,
        HandleRequestErrorsMixin
    ],
    computed: {
        ...sync('SupportTickets', ['form'])
    },
    methods: {
        /**
         * Action load
         * @param background
         * @returns {Promise<boolean>}
         */
        async load(background = false) {

            console.debug('SupportTickets/Show@load: Loading data.', {background})

            background ? this.bgLoading() : this.loading()

            const [err, res] = await SupportTickets.get(this.id)

            this.loaded()

            if (err) {
                console.error('SupportTickets/Show@load erro ao carregar dados', err)
                return this.$alertError(this.handleRequestError(err))
            }

            this.form = res.data.data

            console.debug('SupportTickets/Show@load: Data loaded.', {background})

            return true
        },
        /**
         * Action reply
         * @returns {Promise<boolean>}
         */
        async reply() {
            console.debug("Shipment/SuportTicketReaderParticial@close: Replying SupportTicket.", {id: this.form.id})

            const data = object_to_formdata(this.form)

            this.loading()
            const [err, res] = await SupportTickets.reply(this.form.id, data)
            this.loaded()

            if (err) {
                console.error("Shipment/SuportTicketReaderParticial@close: Error on replying SupportTicket.", err)
                return false
            }

            this.showReplyForm = false
            this.form.body = ""

            this.form = res.data.data

            console.info("Shipment/SuportTicketReaderParticial@close: SupportTicket Replied.", {id: this.form.id})

            return true
        },
        /**
         * INIT
         * @returns {Promise<boolean>}
         */
        async init() {
            await this.load()

            document.title = 'Tristar - ' + this.$t('support.title', [ this.form.id ])
        }
    }
}
