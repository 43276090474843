<template>
	<div class="flex-grow flex flex-col">
		<p
			class="px-2.5 py-2 cursor-pointer group flex items-center text-sm leading-5 font-medium rounded-md text-gray-300 hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"
			:class="{ 'bg-opacity-50 bg-gray-900 text-white' : isOpen }" @click.prevent="handleToggleState">
			<i class="w-6 m-1 flex-shrink-0 text-center text-xl transition ease-in-out duration-150" :class="icon"></i>
			<button type="button" class="flex-1 ml-3 flex justify-between items-center">
				<span>{{ title }}</span>
				<i class="w-6 opacity-75 text-white fa fa-caret-down flex-shrink-0 text-center text-sm transition ease-in-out duration-150"
				   :class="{'fa-caret-down' : !isOpen, 'fa-caret-up' : isOpen}"></i>
			</button>
		</p>
		<!-- Common !-->
		<OpacityScaleTransition enter-extra="translate-x-8">
			<div v-if="isOpen" class="flex flex-col space-y-2 py-2 pl-3 pr-0">
				<slot />
			</div>
		</OpacityScaleTransition>
	</div>
</template>

<script>
import OpacityScaleTransition from "@/components/OpacityScaleTransition.vue"

export default {
	name: "SidebarExpandMenuItem",
	components: {OpacityScaleTransition},
	model: {
		prop: 'open',
		event: 'update:open'
	},
	props: {
		title: {
			type: String,
			required: true
		},
		icon: {
			type: String,
			required: true
		},
		to: {
			type: Object
		},
		openOnPath: String
	},
	data(){
		return {
			isOpen: false
		}
	},
	methods: {
		/**
		 * Handle toggle state
		 */
		handleToggleState() {
			this.isOpen = !this.isOpen

			if(this.isOpen && this.to){
				try { this.$router.push(this.to) } catch(err){ }
			}
		}
	},
	created() {
		if(this.openOnPath) {
			this.isOpen = (new RegExp(this.openOnPath)).test(this.$router.currentRoute.fullPath)
		}
	}
}
</script>