<template>
	<FormSelect :options="list" localized default-option-label="Selecione" :value="value" @input:object="handleSelect" :loading="isLoading" v-bind="$attrs" />
</template>
<script>
import SupportSubjects from "@/API/backoffice/SupportSubjects.js"
import LoadingMixins from "@/mixins/loading.js"
import FormSelect from "@/components/FormSelect.vue"

export default {
	name: "SupportSubjectSelect",
	mixins: [LoadingMixins],
	props: {
		value: [String, Number],
	},
	components: {
		FormSelect
	},
	data() {
		return {
			list: []
		}
	},
	methods: {
		handleSelect(evt){
			this.$emit('input', evt?.id)
			this.$emit('update:departments', evt?.departments)
		},
		/**
		 * Action load
		 * @returns {Promise<boolean>}
		 */
		async load() {

			this.loading()

			const [err, res] = await SupportSubjects.getSelect()

			this.loaded()

			if (err) {
				// TODO...
				this.list = []
				return false
			}

			this.list = res.data.data

			return true
		}
	},
	mounted() {
		this.load()
	}
}
</script>