<template>
	<div class="relative z-[120]" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
		<!-- Background backdrop, show/hide based on slide-over state. -->
		<transition appear enter-active-class="transform transition ease-in-out duration-500" enter-class="opacity-0" enter-to-class="opacity-100"
					leave-active-class="transform transition-all ease-in-out duration-500" leave-class="opacity-100" leave-to-class="opacity-0">
			<div v-if="open" class="fixed inset-0 backdrop-blur-sm bg-black/20" @click="handleClickAway"></div>
		</transition>
		<div class="fixed overflow-hidden">
			<div class="absolute inset-0 overflow-hidden">
				<!-- Content !-->
				<div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-72">
					<transition
						appear
						appear-active-class="transform transition-all ease-in duration-600" appear-class="opacity-0 translate-x-40"
						appear-to-class="translate-x-0 "
						enter-active-class="transform transition-all ease-out duration-600" enter-class="opacity-0 translate-x-40"
						enter-to-class="translate-x-0 "
						leave-active-class="transform transition-all ease-out duration-600" leave-class="translate-x-0" leave-to-class="opacity-0 translate-x-40">
						<div v-if="open" class="slide-container pointer-events-auto" :class="[size]">
							<!-- Wrapper !-->
							<div class="flex h-full flex-col overflow-y-scroll bg-gray-100 shadow-xl">
								<!-- Close !-->
								<div class="absolute top-2 translate-x-[-100%] bg-dark-blue-gray rounded-l-full px-3 py-2 cursor-pointer hover:opacity-75" @click="handleClose">
									<div class="flex items-center justify-between">
										<div class="flex items-center">
											<div class="relative flex items-center text-gray-400 hover:text-gray-500 focus:outline-none">
												<i class="ml-1 fa fa-times text-white text-lg"></i>
												<span v-if="type" class="ml-2 text-white font-semibold">{{ type }}</span>
											</div>
										</div>
									</div>
								</div>
								<!-- Content !-->
								<div class="relative flex-grow flex flex-col px-4">
									<slot />
								</div>
							</div>
							<!-- / Wrapper !-->
						</div>
					</transition>
				</div>
			</div>
		</div>
	</div>

</template>

<script>

export default {
	name: "PageDrawer",
	props: {
		open: Boolean,
		size: {
			type: String,
			default: "w-screen max-w-md"
		},
		type: {
			type: String,
			default: null
		}
	},
	model: {
		prop: "open",
		event: "update:open"
	},
	methods: {
		handleClose() {
			this.$emit("update:open", false)
			this.$emit("close")
		},
		handleCloseByKey(event) {
			if (event?.key === "Escape" && this.open) {
				this.handleClose()
			}
		},
		handleClickAway(event) {
			if (this.open && !event.target.closest(".slide-container")) {
				this.handleClose()
			}
		}
	},
	watch: {
		open: {
			immediate: false,
			handler(v) {
				v ? document.body.classList.add("h-screen", "overflow-hidden") : document.body.classList.remove("h-screen", "overflow-hidden")

				if (v) {
					document.addEventListener("keydown", this.handleCloseByKey)
				} else {
					document.removeEventListener("keydown", this.handleCloseByKey)
				}
			}
		}
	}
}
</script>
