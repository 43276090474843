import { sync } from "vuex-pathify"

import AlertMixin from "@/mixins/alert.js"
import SlideoverMixin from "@/mixins/slideover.js"
import ModalMixin from "@/mixins/modal.js"
import NotificationMixin from "@/mixins/notification.js"

export default {
	mixins: [AlertMixin, ModalMixin, NotificationMixin, SlideoverMixin],
	computed: {
		...sync("APP", ["alert", "modal", "slideover"])
	}
}
