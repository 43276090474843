import {enum_to_hash} from "../helpers.js"

const status = {
    UNKNOWN: 0,
    NOT_REQUESTED: 1,
    ERROR: 2,
    CANCELED: 3,
    PENDING: 10,
    WAITING_PROTOCOL: 20,
    PROCESSING_PROTOCOL: 21,
    ERROR_PROTOCOL: 22,
    WAITING_CONFIRMATION: 30,
    PROCESSING_CONFIRMATION: 31,
    ERROR_CONFIRMATION: 32,
    DONE: 99,
}

export default status

export const options = [
    {id: status.UNKNOWN, text: 'UNKNOWN', label: 'UNKNOWN', class: "bg-gray-200", filter_tab: false},
    {id: status.NOT_REQUESTED, text: 'NOT_REQUESTED', label: 'NOT_REQUESTED', class: "bg-gray-100", filter_tab: false},
    {id: status.ERROR, text: 'ERROR', label: 'ERROR', class: "bg-gray-200", filter_tab: false},
    {id: status.CANCELED, text: 'CANCELED', label: 'CANCELED', class: "bg-red-200 text-red-800", filter_tab: false},
    {id: status.PENDING, text: 'PENDING', label: 'PENDING', class: "bg-yellow-100 text-yellow-800", filter_tab: false},
    {id: status.WAITING_PROTOCOL, text: 'WAITING_PROTOCOL', label: 'WAITING_PROTOCOL', class: "bg-yellow-200 text-yellow-800", filter_tab: false},
    {id: status.PROCESSING_PROTOCOL, text: 'PROCESSING_PROTOCOL', label: 'PROCESSING_PROTOCOL', class: "bg-gray-200", filter_tab: false},
    {id: status.ERROR_PROTOCOL, text: 'ERROR_PROTOCOL', label: 'ERROR_PROTOCOL', class: "bg-gray-200", filter_tab: false},
    {id: status.WAITING_CONFIRMATION, text: 'WAITING_CONFIRMATION', label: 'WAITING_CONFIRMATION', class: "bg-blue-100 text-blue-800", filter_tab: false},
    {id: status.PROCESSING_CONFIRMATION, text: 'PROCESSING_CONFIRMATION', label: 'PROCESSING_CONFIRMATION', class: "bg-blue-200 text-blue-800", filter_tab: false},
    {id: status.ERROR_CONFIRMATION, text: 'ERROR_CONFIRMATION', label: 'ERROR_CONFIRMATION', class: "bg-gray-200", filter_tab: false},
    {id: status.DONE, text: 'DONE', label: 'DONE', class: "bg-green-200 text-green-800", filter_tab: false},
]

export const hash = enum_to_hash(options)