<template>
	<FormSelect :default-option="false" opt-label="description" :options="items" v-bind="$attrs" v-on="$listeners"/>
</template>

<script>
import { sync } from "vuex-pathify"
import Timezones from "@/API/Timezones.js"
import LoadMixin from "@/mixins/loading.js"
import SysMixin from "@/mixins/system.js"
import FormSelect from "@/components/FormSelect.vue"

export default {
	name: "FormSizeUnits",
	mixins: [
		LoadMixin,
		SysMixin
	],
	components: {
		FormSelect
	},
	data() {
		return {
			items: sync('Timezones@list')
		}
	},
	methods: {
		/**
		 * Action load
		 * @returns {Promise<boolean>}
		 */
		async load() {
			console.info('FormTimezone: Loading timezones.')
			this.loading()
			const [err, res] = await Timezones.index()
			this.loaded()

			if (err) {
				console.error('FormTimezone: Error on loading timezones.', err)
				return this.$alertError(this.handleRequestError(err))
			}

			this.items = res.data.data
			console.debug('FormTimezone: Timezones loaded.')
			return true
		},
		/**
		 * INIT
		 */
		init() {
			if(!this.items?.length) this.load()
		}
	},
	mounted() {
		this.init()
	}
}
</script>