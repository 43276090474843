<i18n src="@/i18n/shared/support/form.json"></i18n>
<i18n src="@/i18n/form.json"></i18n>
<template>
	<Page
		:title="$t('support.title')"
		icon-back
		:titleLoading="isBgLoading"
		:loading="isLoading">
		<!-- Action !-->
		<template v-slot:after-title>
			<!-- Save !-->
			<SaveButton class="ml-auto" @click="handleSave"/>
		</template>
		<!-- / Action !-->
		<Card :title="$t('support.general')" class="mt-4">
			<FormGroup>
				<!-- Shipment !-->
				<FormLine :label="$t('support.shipment')">
					<FormInput v-model="form.shipment" :validation="v$.form.shipment" class="max-w-[220px]"/>
				</FormLine>
				<!-- Address !-->
				<FormLine :label="$t('support.recipients') ">
					<div>
						<SupportTicketAddress ref="support_ticket_address" v-model="form.addresses" />
						<!-- Address Actions !-->
						<div class="px-2 flex text-gray-600">
							<!-- Add Client !-->
							<template v-if="!hasClient">
								<button type="button" class="text-xs " @click="handleAddClient">{{ $t('support.include_client') }}</button>
							</template>
							<!-- Clear !-->
							<template v-if="hasAddress">
								<button type="button" class="ml-auto text-xs" @click="handleClearAddress">{{ $t('support.remove_all') }}</button>
							</template>
						</div>
						<!-- / Address Actions !-->
					</div>
				</FormLine>
				<!-- Subject !-->
				<FormLine :label="$t('support.subject')">
					<SupportSubjectSelect v-model="form.support_subject_id" @update:departments="handleSelectSubject" :validation="v$.form.support_subject_id" class="max-w-2xl"/>
				</FormLine>
				<!-- Body !-->
				<FormLine :label="$t('support.message')">
					<FormTextArea v-model="form.body" rows="10" :validation="v$.form.body" />
				</FormLine>
<!--				&lt;!&ndash; Phone !&ndash;&gt;-->
<!--				<FormLine :label="$t('support.phone')">-->
<!--					<FormPhone v-model="form.phone" :validation="v$.form.phone" class="max-w-xl"/>-->
<!--				</FormLine>-->
<!--				&lt;!&ndash; Email !&ndash;&gt;-->
<!--				<FormLine :label="$t('support.email')">-->
<!--					<FormEmail v-model="form.email" :validation="v$.form.email" class="max-w-xl"/>-->
<!--				</FormLine>-->
			</FormGroup>
		</Card>
		<!-- Actions !-->
		<div class="mt-4 px-4 sm:px-0 flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:justify-between">
			<!-- Cancel !-->
			<CancelButton @click="handleCancel"/>
			<!-- Save !-->
			<SaveButton @click="handleSave"/>
		</div>
		<!-- / Actions !-->
	</Page>
</template>

<script>
import Mixin from "./mixins/form.js"

import Page from "@/components/Page.vue"
import Card from "@/components/Card.vue"
import CancelButton from "@/components/CancelButton.vue"
import SaveButton from "@/components/SaveButton.vue"

import FormGroup from "@/components/FormGroup.vue"
import FormLine from "@/components/FormLine.vue"
import FormInput from "@/components/FormInput.vue"
import FormTextArea from "@/components/FormTextArea.vue"
import FormPhone from "@/components/FormPhone.vue"
import FormEmail from "@/components/FormEmail.vue"
import SupportSubjectSelect from "@/domain/support_subjects/SupportSubjectSelect.vue";
import SupportTicketAddress from "@/domain/support_tickets/SupportTicketAddress.vue";

export default {
	name: "FormPage",
	mixins: [Mixin],
	components: {
		SupportTicketAddress,
		Page,
		Card,
		FormTextArea,
		FormGroup,
		FormLine,
		FormInput,
		FormPhone,
		FormEmail,
		SaveButton,
		CancelButton,
		SupportSubjectSelect,
	},
	computed: {
		/**
		 * Check if has client in addresses
		 * @returns {boolean}
		 */
		hasClient(){
			return this.form?.addresses?.findIndex(item => item.address_type === 'partner' && item.id == this.shipment.partner_id) !== -1
		},
		/**
		 * Check if has address
		 * @returns {boolean}
		 */
		hasAddress(){
			return this.form.addresses?.length > 0
		}
	},
	methods: {
		/**
		 * Handler save
		 * @returns {Promise<void>}
		 */
		async handleSave() {
			if(await this.save()) {
				this.$alertFormSaved()
				this.$router.push({name: "supports.index"})
			}
		},
		/**
		 * Handler cancel
		 */
		handleCancel(){
			this.$router.push({name: "supports.index"})
		},
		/**
		 * Handle add client
		 */
		async handleAddClient() {
			if (this.hasClient) return false

			// For partner
			if (this.shipment?.partner_id) {
				await this.$refs.support_ticket_address.addItem('partner', this.shipment.partner_id, false)
			}

			// For provider
			if (this.shipment?.provider_id) {
				await this.$refs.support_ticket_address.addItem('provider', this.shipment.provider_id, false)
			}

			// For client
			if (this.shipment?.client_id) {
				await this.$refs.support_ticket_address.addItem('client', this.shipment.client_id, false)
			}

			return true
		},
		/**
		 * Handle clear address
		 */
		handleClearAddress() {
			this.form.addresses = []
		},
		/**
		 * Handle select subject
		 * @param evt
		 * @returns {Promise<boolean|boolean>}
		 */
		async handleSelectSubject(evt){

			if(this.lastDepartment) {
				for(let department of this.lastDepartment) {
					await this.$refs.support_ticket_address.removeItem('department', department)
				}
			}

			if(!evt) return false

			this.lastDepartment = evt
			for(let department of evt) {
				await this.$refs.support_ticket_address.addItem('department', department)
			}

		}
	},
	mounted() {
		this.init()
	}
}
</script>
