<i18n src="@/i18n/backoffice/dashboard/totals_partial.json"></i18n>
<template>
    <div>
        <!-- Title !-->
        <h3 class="text-lg leading-6 font-medium text-gray-900 pl-4 sm:pl-0">
            {{ $t("dashboard.totals_partial.title") }}
        </h3>
        <!-- Blocks !-->
        <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
			<!-- Total Shipments !-->
			<KPICard :title="$t('dashboard.totals_partial.total_shipments')" :chart="shipmentChartData" class="bg-gradient-to-t from-gray-100">
				<i class="fal text-2xl" :class="shipmentArrow"></i>
				<div class="flex items-end">
					<!-- Total current period !-->
					<span class="ml-4 text-3xl font-semibold text-gray-900">{{ statistics.shipments.total_month }}</span>
					<!-- Percent !-->
					<div v-if="percentShipments" class="ml-3 flex items-center space-x-2">
						<i class="fal fa-slash-forward"></i>
						<!-- Percent !-->
						<span class="ml-2 font-light text-gray-600">{{ percentShipments }} <i class="fal fa-percent text-gray-600"></i></span>
					</div>
				</div>
			</KPICard>
            <!-- Total Clients !-->
			<KPICard :title="$t('dashboard.totals_partial.total_clients')" :chart="clientChartData" class="bg-gradient-to-t from-gray-100">
				<i class="fal text-2xl"  :class="clientArrow"></i>
				<div class="flex items-end">
					<!-- Total current period !-->
					<span class="ml-4 text-3xl font-semibold text-gray-900">{{ statistics.clients.total_month }}</span>
					<div v-if="percentClient" class="ml-3 flex items-center space-x-2">
						<i class="fal fa-slash-forward"></i>
						<!-- Percent !-->
						<span class="font-light text-gray-600">{{ percentClient }} <i class="fal fa-percent text-gray-600"></i></span>
					</div>
				</div>
			</KPICard>
            <!-- Total Tickets !-->
			<KPICard :title="$t('dashboard.totals_partial.total_tickets')" :chart="supportChartData" class="bg-gradient-to-t from-gray-100">
				<i class="fal text-2xl"  :class="supportTicketArrow"></i>
				<div class="flex items-end">
					<!-- Total current period !-->
					<span class="ml-4 text-3xl font-semibold text-gray-900">{{ statistics.support_tickets.total_month }}</span>
					<div v-if="percentSupportTicket" class="ml-3 flex items-center space-x-2">
						<i class="fal fa-slash-forward"></i>
						<!-- Percent !-->
						<span class="ml-2 font-light text-gray-600">{{ percentSupportTicket }} <i class="fal fa-percent text-gray-600"></i></span>
					</div>
				</div>
			</KPICard>
        </dl>
        <!-- /Blocks !-->
    </div>
</template>

<script>
import KPICard from "@/components/KPICard.vue";

export default {
    name: "TotalsPartial",
	components: {KPICard},
    inject: ['dashboard'],
	computed: {
		statistics(){
			if(!this.dashboard?.totals?.statistics) {
				return {
					shipments: {total_month: 0, total_last_period: 0, chart: null},
					clients: {total_month: 0, total_last_period: 0, chart: null},
					support_tickets: {total_month: 0, total_last_period: 0, chart: null},
				}
			}

			return this.dashboard.totals.statistics
		},
		percentShipments(){
			if(!this.statistics.shipments.total_month) return
			if(!this.statistics.shipments.total_last_period) return this.statistics.shipments.total_month * 100
			return Math.abs((this.statistics.shipments.total_month / this.statistics.shipments.total_last_period) * 100).toFixed()
		},
		shipmentArrow(){
			if(!this.statistics.shipments.chart) return 'fa-minus text-gray-600'

			return this.statistics.shipments.total_last_period > this.statistics.shipments.total_month
				? 'fa-arrow-down text-red-600'
				:'fa-arrow-up text-green-600'
		},
		percentClient(){
			if(!this.statistics.clients.total_month) return
			if(!this.statistics.clients.total_last_period) return this.statistics.clients.total_month * 100
			return Math.abs((this.statistics.clients.total_month / this.statistics.clients.total_last_period) * 100).toFixed()
		},
		clientArrow(){
			if(!this.statistics.clients.chart) return 'fa-minus text-gray-600'

			if(this.statistics.clients.total_last_period === this.statistics.clients.total_month){
				return 'fa-minus text-gray-600'
			}

			return this.statistics.clients.total_last_period > this.statistics.clients.total_month
				? 'fa-arrow-down text-red-600'
				:'fa-arrow-up text-green-600'
		},
		percentSupportTicket(){
			if(!this.statistics.support_tickets.total_month) return
			if(!this.statistics.support_tickets.total_last_period) return this.statistics.support_tickets.total_month * 100
			return Math.abs((this.statistics.support_tickets.total_month / this.statistics.support_tickets.total_last_period) * 100).toFixed()
		},
		supportTicketArrow(){
			if(!this.statistics.support_tickets.chart) return 'fa-minus text-gray-600'

			if(this.statistics.support_tickets.total_last_period === this.statistics.support_tickets.total_month){
				return 'fa-minus text-gray-600'
			}

			return this.statistics.support_tickets.total_last_period > this.statistics.support_tickets.total_month
				? 'fa-arrow-down text-red-600'
				:'fa-arrow-up text-green-600'
		},
		shipmentChartData(){
			return {
				data: this.statistics.shipments?.chart ?? [],
				borderColor: '#94a3b8'
			}
		},
		clientChartData(){
			return {
				data: this.statistics.clients?.chart ?? [],
				borderColor: '#94a3b8'
			}
		},
		supportChartData(){
			return {
				data: this.statistics.support_tickets?.chart ?? [],
				borderColor: '#94a3b8'
			}
		}
	}
}
</script>
