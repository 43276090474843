<i18n src="@/i18n/enums/anvisa_solicita_status.json"></i18n>
<template>
	<FloatContextMenu :target="target" :open="show" @update:open="$emit('update:open', $event)">
		<ContextMenuItem icon="fa fa-link" text="Abrir Remessa" @click="handleOpenShipmentContext"/>
		<ContextMenuItem v-if="canSend" icon="fa fa-edit" text="Solicitar" @click="handleSendShipmentContext"/>
		<ContextMenuItem v-if="canCancel" icon="fa fa-times" text="Cancelar" @click="handleCancelContext"/>
		<ContextMenuItem v-if="canComplete" icon="fa fa-check" text="Marcar como concluído" @click="handleCompleteContext"/>
	</FloatContextMenu>
</template>

<script>
import AnvisaSolicita from "@/API/backoffice/AnvisaSolicita.js"
import AnvisaSolicitaStatus from "@/enums/anvisa_solicita_status.js"

import SystemMixin from "@/mixins/system.js"
import LoadingEmitterMixin from "@/mixins/loading_emitter.js"

import FloatContextMenu from "@/components/FloatContextMenu.vue"
import ContextMenuItem from "@/components/ContextMenuItem.vue"

export default {
	name: "AnvisaSolicitaContextMenu",
	mixins: [SystemMixin, LoadingEmitterMixin],
	components: {ContextMenuItem, FloatContextMenu},
	props: {
		anvisaSolicita: Object,
		target: HTMLElement,
		show: Boolean
	},
	model: {
		prop: 'show',
		event: 'update:open'
	},
	computed: {
		canSend() {
			if (this.anvisaSolicita == null) return false

			return this.anvisaSolicita.anvisa_solicita_status !== AnvisaSolicitaStatus.PENDING
		},
		canCancel() {
			if (this.anvisaSolicita == null) return false

			return this.anvisaSolicita.anvisa_solicita_status === AnvisaSolicitaStatus.PENDING
		},
		canComplete() {
			if (this.anvisaSolicita == null) return false

			return this.anvisaSolicita.anvisa_solicita_status !== AnvisaSolicitaStatus.DONE
		}
	},
	methods: {
		/**
		 * Handle Open Shipment Context Menu
		 */
		handleOpenShipmentContext() {
			window.open(this.$router.resolve({name: 'shipments.show', params: {id: this.anvisaSolicita.shipment.id}}).href, '_blank')
		},
		/**
		 * Handle Open Shipment Context Menu
		 */
		async handleSendShipmentContext() {
			if (await this.changeStatus({id: this.anvisaSolicita.id, status: AnvisaSolicitaStatus.PENDING})) {
				this.$alertSuccess(`Anvisa Solicita para remessa #${this.anvisaSolicita.shipment.tracking_code} solicitada.`)
			}

			this.load(true)
		},
		/**
		 * Handle Reset Process Solicita
		 */
		async handleCancelContext() {
			if (await this.changeStatus({id: this.anvisaSolicita.id, status: AnvisaSolicitaStatus.CANCELED})) {
				this.$alertSuccess(`Anvisa Solicita #${this.anvisaSolicita.id} cancelado.`)
			}

			this.load(true)
		},
		/**
		 * Handle Complete Process Solicita
		 * @returns {Promise<void>}
		 */
		async handleCompleteContext() {
			if (await this.changeStatus({id: this.anvisaSolicita.id, status: AnvisaSolicitaStatus.DONE})) {
				this.$alertSuccess(`Anvisa Solicita #${$this.anvisaSolicita.id} marcado como concluído.`)
			}

			this.load(true)
		},
		/**
		 * Action change AnvisaSolicita status
		 * @param data { Object }
		 * @returns {Promise<boolean>}
		 */
		async changeStatus(data) {
			console.debug("AnvisaSolicitaContextMenu@changeStatus: Sending data.")

			this.loading()
			const [err, res] = await AnvisaSolicita.changeStatus(data)
			this.loaded()

			if (err) {
				console.error("AnvisaSolicitaContextMenu@changeStatus: Error sending data.", {err})
				return this.$alertError(this.handleRequestError(err))
			}

			console.info("AnvisaSolicitaContextMenu@changeStatus: Data send.")

			return true
		},
	}
}
</script>