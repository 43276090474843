<template>
	<!-- Welcome !-->
	<router-link :to="to"
				 class="px-2.5 py-2 group flex flex-shrink-0 items-center text-sm leading-5 font-medium rounded-md hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:bg-white focus:bg-opacity-10 transition ease-in-out duration-150"
				 v-bind="linkClass">
		<i class="w-6 m-1 flex-shrink-0 text-center text-lg transition ease-in-out duration-150" :class="icon"></i>
		<span class="ml-3">{{ title }}</span>
	</router-link>
</template>
<script>
export default {
	name: "SidebarMenuItem",
	props: {
		title: {
			type: String,
			required: true
		},
		to: {
			type: [String, Object],
			required: true
		},
		icon: {
			type: String,
			required: true
		},
		activeOnExact: Boolean
	},
	computed: {
		linkClass() {
			return this.activeOnExact ?{'exact-active-class': 'bg-opacity-50 bg-gray-900 text-white'} : {'active-class': 'bg-opacity-50 bg-gray-900 text-white'}
		}
	}
}
</script>