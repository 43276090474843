<i18n src="@/i18n/enums/support_ticket_status.json"></i18n>
<template>
	<TabFilter v-if="page.meta" :list="filterableStatus" :totals="totalPerStatus" :selected="selected" @selected="handleSelectStatus"/>
</template>

<script>
import _Filter from "lodash/filter.js"
import {options as SupportTicketStatusOptions} from "@/enums/support_ticket_status.js"
import {translateEnum} from "@/helpers.js"

import SupportTicketStatus from "@/enums/support_ticket_status.js"
import TabFilter from "@/components/TabFilter.vue"
import ShipmentCustomFilterButton from "@/domain/shipments/ShipmentCustomFilterButton.vue"

export default {
    name: "FilterStatusBar",
    props: {
        page: {
            type: [Object, Array],
            required: true
        },
        selected: {
            type: [Number, String],
            default: null
        },
        debug: Boolean
    },
    components: {
        ShipmentCustomFilterButton,
        TabFilter
    },
    computed: {
        /**
         * List of status count
         * @returns {*|*[]}
         */
        totalPerStatus() {
            return this.page?.meta?.total_per_status ?? []
        },
        /**
         * List of filterable status list (translated)
         * @returns {[{label: VueI18n.TranslateResult, class: string, shipment_status: null},...*]}
         */
        filterableStatus() {
            // const list = _Filter(SupportTicketStatus, {'filter_tab': true});

            return [
                {
                    id: -1,
                    label: this.$t('Meus chamados'),
                    class: 'bg-gray-200'
                },
                {
                    id: null,
                    label: this.$t('support_ticket_status.All'),
                    class: 'bg-gray-200'
                },
                {
                    id: SupportTicketStatus.OPEN,
                    label: this.$t('support_ticket_status.Open'),
                    class: 'bg-gray-200'
                },
                {
                    id: SupportTicketStatus.CLOSED,
                    label: this.$t('support_ticket_status.Closed'),
                    class: 'bg-gray-200'
                },
                {
                    id: SupportTicketStatus.ARCHIVED,
                    label: this.$t('support_ticket_status.Archived'),
                    class: 'bg-gray-200'
                },
            ]
        }
    },
    methods: {
        /**
         * Handler select status
         */
        handleSelectStatus(status) {
            if (this.debug) console.debug("FilterStausBar@handleSelectStatus", {status})
            this.$emit('select', status?.id)
        }
    }
}
</script>