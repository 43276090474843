import Http from "@/config/axios.js"
import {to} from "await-to-js"

export default {
    /**
     * List all anvisa_solicita
     * @param params
     * @returns {Promise<[(Error | null), (T | undefined)]>}
     */
    async list(params) {
        return to(Http.get("/backoffice/anvisa_solicita", {params}))
    },
    /**
     * Get a anvisa_solicita
     * @param id
     * @returns {Promise<[(Error | null), (T | undefined)]>}
     */
    async get(id) {
        return to(Http.get(`/backoffice/anvisa_solicita/${id}`))
    },
    /**
     * Get a list of anvisa_solicita for select
     * @param params
     * @returns {Promise<[(Error|null),(T|undefined)]>}
     */
    async getSelect(params = {}) {
        return this.list({_type: "select", ...params})
    },
    /**
     * Request anvisa_solicita creation
     * @param data
     * @returns {Promise<[(Error | null), (T | undefined)]>}
     */
    async create(data) {
        return to(Http.post(`/backoffice/anvisa_solicita`, data))
    },
    /**
     * Insert a anvisa_solicita
     * @param data
     * @returns {Promise<[(Error | null), (T | undefined)]>}
     */
    async insert(data) {
        return to(Http.post(`/backoffice/anvisa_solicita`, data))
    },
    /**
     * Update a anvisa_solicita
     * @param id
     * @param data
     * @returns {Promise<[(Error | null), (T | undefined)]>}
     */
    async update(id, data) {
        data.append('_method', 'patch')
        return to(Http.post(`/backoffice/anvisa_solicita/${id}`, data))
    },
    /**
     * Save a anvisa_solicita
     * @param data
     * @param id
     * @returns {Promise<[(Error|null),(T|undefined)]>}
     */
    async save(data, id) {
        return id ? this.update(id, data) : this.insert(data)
    },
    /**
     * Delete a anvisa_solicita
     * @param id
     * @returns {Promise<[(Error | null), (T | undefined)]>}
     */
    async delete(id) {
        return to(Http.post(`/backoffice/anvisa_solicita/${id}`, {'_method': 'delete'}))
    },
    async resendStatus(status) {
        return to(Http.post(`/backoffice/anvisa_solicita/resend`, {status}))
    },
    /**
     * Change Status
     * @param data { Object }
     * @returns {Promise<[Error | null, T | undefined]>}
     */
    async changeStatus(data){
        return to(Http.post(`/backoffice/anvisa_solicita/change-status`, data))
    }
}

