import Http from "@/config/axios.js"
import {to} from "await-to-js"

export default {
    async list(params) {
        return to(Http.get("/backoffice/support-tickets", {params}))
    },
    async get(id) {
        return to(Http.get(`/backoffice/support-tickets/${id}`))
    },
    async create(params) {
        return to(Http(`/backoffice/support-tickets`, {params}))
    },
    async insert(data) {
        return to(Http.post(`/backoffice/support-tickets`, data))
    },
    async update(id, data) {
        data.append('_method', 'patch')
        return to(Http.post(`/backoffice/support-tickets/${id}`, data))
    },
    async save(data, id) {
        return id ? this.update(id, data) : this.insert(data)
    },
    async delete(id) {
        return to(Http.post(`/backoffice/support-tickets/${id}`, {'_method': 'delete'}))
    },
    /**
     * Search address
     * @param params
     * @returns {Promise<[(Error | null), (T | undefined)]>}
     */
    async searchAddress(params) {
        return to(Http.post(`/backoffice/support-ticket-address`, params))
    },
    /**
     * Address Options
     * @param params
     * @returns {Promise<[(Error | null), (T | undefined)]>}
     */
    async addressOptions(params) {
        return to(Http.post(`/backoffice/support-ticket-address-options`, params))
    },
    async close(id) {
        return to(Http.post(`/backoffice/support-tickets/${id}/close`))
    },
    async archive(id) {
        return to(Http.post(`/backoffice/support-tickets/${id}/archive`))
    },
    async reply(id, data) {
        return to(Http.post(`/backoffice/support-tickets/${id}/reply`, data))
    },
    async appendRecipient(id, data) {
        return to(Http.post(`/backoffice/support-tickets/${id}/append-recipient`, data))
    },
}

