import { render, staticRenderFns } from "./MenuLinks.vue?vue&type=template&id=1a3f859c&"
import script from "./MenuLinks.vue?vue&type=script&lang=js&"
export * from "./MenuLinks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/i18n/partner/menu_links.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2FUsers%2Fyurinascimento%2FDocuments%2FWork%2Ftristar%2Ftristar-courier-frontend%2Fsrc%2Flayout%2Fpartner%2FMenuLinks.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports