<i18n src="@/i18n/components/FormFile.json"></i18n>
<template>
	<div>
		<div
			class="relative bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-turquoise">
			<label :for="name" class="relative text-sm pointer-events-none">
				<slot v-bind:default="{ file: file, fileSelected: fileSelected }">
					<span>{{ fileSelected ? $t('form_file.file_selected') : $t('form_file.select_file') }}</span>
				</slot>
			</label>
			<input :id="name" :name="name" type="file" :multiple="multiple" class="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md" @input="handleFileSelected($event.target)">
		</div>
	</div>
</template>

<script>
export default {
	name: "FormFile",
	props: {
		value: [Object, File, FileList],
		name: {
			type: String,
			required: true
		},
		multiple: Boolean
	},
	data(){
		return {
			file: null
		}
	},
	computed: {
		filename() {
			return this.file?.name
		},
		fileSelected() {
			return !!this.file
		}
	},
	methods: {
		/**
		 *
		 * @param t
		 */
		handleFileSelected(t) {
			this.file = t.files?.length ? this.multiple ? t.files : t.files[0] : {}
			this.$emit("input", this.multiple ? t.files : t.files[0])
		}
	}
}
</script>