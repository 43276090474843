<template>
	<div class="flex border-t border-gray-200 pt-4">
		<slot />
	</div>
</template>

<script>
export default {
	name: "PageFooterActions"
}
</script>