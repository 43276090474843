<template>
	<div class="p-2 bg-white mb-2 shadow">
		<h3>{{ title }}</h3>
		<p>{{ description }}</p>
	</div>
</template>

<script>
export default {
	name: "KanbanCard",
	props: {
		id: [String, Number],
		title: String,
		description: String
	}
}
</script>