import {make} from "vuex-pathify"

const state = {
    isLoading: false,
    form: {
        code: "",
        title_locale: {},
        description_locale: {},
        members: []
    },
    page: []
}

const mutations = make.mutations(state)

const actions = {
    loading({commit}) {
        commit('SET_IS_LOADING', true)
    },
    loaded({commit}) {
        commit('SET_IS_LOADING', false)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
