<template>
	<a @click="handleClick"
	   class="group flex items-center px-4 py-2 text-sm cursor-pointer leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
	   :class="{ 'border-t border-gray-200' : divider }"
	   role="menuitem">
		<slot>{{ text }}</slot>
		<template v-if="icon">
			<i class="ml-auto" :class="$tw('text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 w-[14px] text-center', icon)"></i>
		</template>
	</a>
</template>

<script>
export default {
	name: "ContextMenuItem",
	props: {
		text: String,
		icon: String,
		value: [String, Number, Object],
		divider: Boolean
	},
	inject: ['dropdownState'],
	methods: {
		/**
		 * Handle click
		 */
		handleClick() {
			this.dropdownState.show = false
			this.$emit('click', this.value)
		}
	}
}
</script>
