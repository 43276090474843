<template>
	<div class="flex-1 flex border border-red-500 min-w-0 overflow-x-auto">
		<slot />
		<div class="flex-grow"></div>
	</div>
</template>

<script>
export default {
	name: 'KanbanContainer'
}
</script>