<i18n src="@/i18n/shared/support/list.json"></i18n>
<template>
	<Page
		:title="$t('Suporte')"
		icon="fa fa-user-headset text-turquoise"
		class="min-h-full flex flex-col"
		:titleLoading="isBgLoading"
		@title-action="handleLoad(true)">
		<!-- Action !-->
		<template v-slot:after-title>
			<!-- Create !-->
			<CreateButton v-if="hasData" :to="{ name: 'supports.create' }" class="ml-auto"/>
		</template>
		<!-- Status Filters !-->
		<div class="flex items-center h-12">
			<FilterStatusBar v-show="view === 'list'" :page="page" :selected="filter.status" @select="handleSelectStatusFilter"/>
			<!--			<div class="flex space-x-2 ml-auto items-center">-->
			<!--				<button type="button" @click="view = 'list'" :class="view === 'list' && 'text-turquoise'">-->
			<!--					<i class="fal fa-list"></i>-->
			<!--				</button>-->
			<!--				<button type="button" @click="view = 'card'" :class="view === 'card' && 'text-turquoise'">-->
			<!--					<i class="fal fa-cards-blank"></i>-->
			<!--				</button>-->
			<!--			</div>-->
		</div>
		<template v-if="view === 'list'">
			<!-- Card !-->
			<Card title="Lista de chamados">
				<template v-if="hasData">
					<!-- Table !-->
					<AutoTable v-bind="table()" v-model="page.data" @row-click="handleSelected" @row-context="handleContextRow">
						<template #col-shipment="{ data: { row } }">
							<p>{{ $data_get(row.shipment, 'tracking_code') }}</p>
						</template>
						<template #col-subject="{ data: { row } }">
							<p>{{ $localize(row.subject, 'title_locale') }}</p>
							<div class="mt-px inline-flex space-x-2 text-xs">
								<p class="inline-flex space-x-2">
									<span>
										<i class="fal fa-user text-xs"></i>
									</span>
									<span>{{ row.created_by.name }}</span>
								</p>
								<p class="inline-flex space-x-2">
									<span>
										<i class="fal fa-arrow-right text-xs"></i>
									</span>
									<span>{{ destinationAddresses(row) }}</span>
								</p>
							</div>
						</template>
						<template #col-status="{ data: { row } }">
							<!-- {{ row.support_ticket_status }} -->
							<SupportTicketStatusBadge :support_ticket="row"/>
						</template>
					</AutoTable>
					<Pagination v-bind="page.meta" @select-page="handleSelectPage"/>
				</template>
				<!-- / Table !-->
				<!-- Empty Filters !-->
				<template v-else-if="hasFilter">
					<p class="p-4 text-base text-center">
						{{ $t('support_list.no_results_for_filter') }}
					</p>
				</template>
				<!-- Empty !-->
				<template v-else>
					<div class="p-4 h-96 space-y-8 flex flex-col text-base justify-center items-center">
						<p>{{ $t('support_list.empty') }}</p>
						<CreateButton :to="{ name: 'supports.create' }"/>
					</div>
				</template>
				<!-- / Table !-->
			</Card>
			<!-- / Card !-->
		</template>
		<template v-else>
			<!--			{{ page.data }}-->
			<KanbanContainer class="">
				<KanbanStage title="Teste" :data="stageData"/>
			</KanbanContainer>
		</template>
		<!-- ContextMenu !-->
		<SupportTicketContextMenu v-bind="contextMenu" v-model="showContextMenu" @isLoading="handleLoad(true)"/>
	</Page>
</template>

<script>
import Mixin from "./mixins/index.js"

import Page from "@/components/Page.vue"
import Card from "@/components/Card.vue"
import Loading from "@/components/Loading.vue"
import AutoTable from "@/components/AutoTable2.vue"
import Pagination from "@/components/Pagination.vue"
import TableFilter from "@/components/TableFilter.vue"
import Dropdown from "@/components/Dropdown.vue"

import FormLine from "@/components/FormLine.vue"
import FormRadio from "@/components/FormRadio.vue"
import FormInput from "@/components/FormInput.vue"
import FormSelect from "@/components/FormSelect.vue"
import FormDatePicker from "@/components/FormDatePicker.vue"
import SupportTicketStatusBadge from "@/domain/support_tickets/SupportTicketStatusBadge.vue"
import FilterStatusBar from "./partials/FilterStatusBar.vue"
import CreateButton from "@/components/CreateButton.vue"
import AnvisaSolicitaContextMenu from "@/domain/anvisa_solicita/AnvisaSolicitaContextMenu.vue";
import SupportTicketContextMenu from "@/domain/support_tickets/SupportTicketContextMenu.vue";
import KanbanContainer from "@/components/KanbanContainer.vue";
import KanbanStage from "@/components/Kanban/KanbanStage.vue";

export default {
	name: "IndexPage",
	mixins: [Mixin],
	components: {
		KanbanStage,
		KanbanContainer,
		SupportTicketContextMenu,
		CreateButton,
		Page,
		Card,
		AutoTable,
		Pagination,
		TableFilter,
		SupportTicketStatusBadge,
		FilterStatusBar
	},
	data() {
		return {
			showContextMenu: false,
			contextMenu: {
				supportTicket: null,
				target: null
			},
			view: 'list',
			stageData: [
				{
					id: 1,
					title: "Teste 1",
				},
				{
					id: 2,
					title: "Teste 2",
				},
				{
					id: 3,
					title: "Teste 3",
				}
			]
		}
	},
	computed: {
		/**
		 * Check if has data
		 * @returns {boolean}
		 */
		hasData() {
			return this.page?.meta?.total > 0
		},
		hasFilter() {
			return false
		},
		actions() {
			return []
		}
	},
	methods: {
		handleLoad(background) {
			this.load(background)
		},
		/**
		 * Handle selected row
		 * @param row
		 */
		handleSelected(e, row) {
			if (e.metaKey || e.ctrlKey) {
				return window.open(this.$router.resolve({name: 'support_tickets.show', params: {id: row.id}}).href, '_blank')
			}

			this.$router.push({name: 'support_tickets.show', params: {id: row.id}})
		},
		/**
		 * Handle select page
		 * @param page
		 */
		handleSelectPage(page) {
			this.filter.page = page
			this.load()
		},
		/**
		 * Handle select filter status
		 * @param evt
		 */
		handleSelectStatusFilter(evt) {
			this.filter.status = evt
			this.load()
		},
		handleContextRow(e) {
			e.evt.stopPropagation()
			e.evt.preventDefault();

			this.$set(this, 'contextMenu', {
				supportTicket: this.page.data[e.rowIndex],
				target: e.evt.target.closest('tr')
			})

			this.showContextMenu = true
		},
		destinationAddresses(row) {
			return row.addresses.map(item => {
				if (item.address_type === 'user') {
					return item.label
				}

				return this.$localize(item.label)
			}).join(', ')
		},
	},
	mounted() {
		this.init()
	}
}
</script>
